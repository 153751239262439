import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import backgroundImg from "./path-bg.svg";
import pathLogo from "./path-logo.png";
import circleSvg from "./circle.svg";
import arrowSvg from "./arrow.svg";
import Button from "../components/shared/Button";
import introImage from "../pages/paths/intro.png";
import landingImage from "../pages/paths/landing.png";
import styledImage from "../pages/paths/styled.png";
import { useSelector } from "react-redux";

const LessonRow = styled.div`
  padding: 8px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: black;
  border: 1px solid #e5e0e0;
  border-radius: 5px;
  font-size: 18px;
  background: #edeef8;
  &:hover {
    background-color: #e5e0e0;
  }
  ${({ active }) =>
    active &&
    `
    font-weight: bold; 
  `}
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  max-width: 2000px;
  opacity: 0.5;
`;

const PathLogo = styled.img`
  max-width: 100%;
  width: 100%;
  margin: auto;
`;

const PathTitle = styled.h1`
  margin-top: 80px;
  font-size: 64px;
`;

const Arrow = styled.img`
  opacity: 0.4;
  height: 15px;
`;

export const Menu = ({ children, lessonIndex }) => {
  const loggedInUser = useSelector((state) => state.user.user);

  return (
    <div style={{ maxWidth: "647px" }}>
      {children.map(({ title, slug }, i) => (
        <Link to={loggedInUser ? slug : "/join"}>
          {" "}
          <LessonRow active={i === lessonIndex}>
            <img style={{ paddingRight: 15 }} src={circleSvg} />
            <div style={{ flexGrow: 1 }}> {title} </div>
            <Arrow src={arrowSvg} />
          </LessonRow>{" "}
        </Link>
      ))}{" "}
    </div>
  );
};

function Path({
  data: {
    path: { title, description, description_bullets, childrenLesson },
  },
}) {
  const loggedInUser = useSelector((state) => state.user.user);

  return (
    <Layout otherChildren={<BackgroundImage src={backgroundImg} />}>
      <div style={{ display: "flex" }}>
        <div style={{ flexBasis: "647px" }}>
          <Seo title={title} />
          <PathTitle>{title}</PathTitle>
          <p>
            <h2> Description </h2>
            <p> {description} </p>
            {description_bullets !== "No bullets" && (
              <>
                <h3> You will learn: </h3>
                <ul>
                  {description_bullets.split(",").map((bullet) => (
                    <li> {bullet} </li>
                  ))}
                </ul>
              </>
            )}
            <p></p>
          </p>
          {loggedInUser ? (
            <Link
              to={childrenLesson && childrenLesson[0] && childrenLesson[0].slug}
            >
              <Button> Start >> </Button>
            </Link>
          ) : (
            <Link to="/join">
              <Button> Join to Access </Button>
            </Link>
          )}
        </div>
      </div>
      <div style={{ paddingTop: "80px" }}>
        <h2> Lessons in this module </h2>
        <Menu children={childrenLesson} />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    path(id: { eq: $id }) {
      slug
      title
      description
      description_bullets
      childrenLesson {
        title
        slug
      }
    }
  }
`;

export default Path;
